// GSAP IMPORTS

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// FULL SCREEN OVERLAY NAVIGATION
document.addEventListener("DOMContentLoaded", () => {
  const navToggle = document.getElementById("nav-toggle");
  const overlay = document.getElementById("overlay");
  const closeBtn = document.getElementById("close-btn");
  const navLinks = document.querySelectorAll("#overlay nav a");

  let overlayOpen = false;

  const openOverlay = () => {
    gsap.to(overlay, {duration: 1, opacity: 1, visibility: "visible", ease: "power3.inOut"});
    overlayOpen = true;
  };

  const closeOverlay = () => {
    gsap.to(overlay, {duration: 1, opacity: 0, visibility: "hidden", ease: "power3.inOut"});
    overlayOpen = false;
  };

  // Toggle overlay with button click
  navToggle.addEventListener("click", () => { 
    overlayOpen ? closeOverlay() : openOverlay();
  });

  // Close overlay with close button click
  closeBtn.addEventListener("click", () => {
    closeOverlay();
  });

  // Close overlay when clicking outside the nav links
  overlay.addEventListener("click", (event) => {
    if (event.target === overlay) {
      closeOverlay();
    }
  });

  // Close overlay when clicking on any of the nav links
  navLinks.forEach((link) => {
    link.addEventListener("click", () => {
      closeOverlay();
    });
  });

  // Close overlay with Esc key
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape" && overlayOpen) {
      closeOverlay();
    }
  });
});

// Create GSAP matchMedia to handle different screen sizes
var mm = gsap.matchMedia();

// Define small screen behavior (below 768px for Tailwind's md breakpoint)
mm.add("(max-width: 767px)", () => {
  // Master timeline for slideshow and loadbars on small screens
  var tl = gsap.timeline({repeat:-1, repeatDelay:0});

  // Slide 1: Hero1 and Loadbar1
  tl.to(".loadbar1", {width:"100%", duration:7, ease:"none"})
    .to(".call1", {display: "block", opacity:1}, "<")  // Show text for active slide
    .to(".loadbar1", {width:"0", duration:0})
    .to(".call1", {display: "none", opacity: 0}, "<")  // Hide inactive text
    .to(".hero1", {autoAlpha:0, duration:1}, "-=1")  // Fade out the first slide

  // Slide 2: Hero2 and Loadbar2
    .to(".loadbar2", {width:"100%", duration:7, ease:"none"})
    .to(".call2", {display: "block", opacity:1}, "<")
    .to(".loadbar2", {width:"0", duration:0})
    .to(".call2", {display: "none", opacity: 0}, "<")
    .to(".hero2", {autoAlpha:0, duration:1}, "-=1")  // Fade out the second slide

  // Slide 3: Hero3 and Loadbar3
    .to(".loadbar3", {width:"100%", duration:7, ease:"none"})
    .to(".call3", {display: "block", opacity:1}, "<")
    .to(".loadbar3", {width:"0", duration:0})
    .to(".call3", {display: "none", opacity: 0}, "<")
    .to(".hero1", {autoAlpha:1, duration:1}, "-=1")  // Fade in the first slide to loop back
    .to(".hero3", {autoAlpha:0, duration:1}, "-=1"); // Fade out the third slide
});

// Define large screen behavior (768px and above for Tailwind's md breakpoint)
mm.add("(min-width: 768px)", () => {
  // Master timeline for slideshow and loadbars on large screens
  var tl = gsap.timeline({repeat:-1, repeatDelay:0});
  
  // Slide 1: Hero1 and Loadbar1
  tl.to(".loadbar1", {width:"100%", duration:7, ease:"none"})
    .to(".hero-text1", {opacity: 1}, "<")
    .to(".loadbar1", {width:"0", duration:0})
    .to(".hero-text1", {opacity: 0.7}, "<")
    .to(".hero1", {autoAlpha:0, duration:1}, "-=1")  // Fade out the first slide

  // Slide 2: Hero2 and Loadbar2
    .to(".loadbar2", {width:"100%", duration:7, ease:"none"})
    .to(".hero-text2", {opacity: 1}, "<")
    .to(".loadbar2", {width:"0", duration:0})
    .to(".hero-text2", {opacity: 0.7}, "<")
    .to(".hero2", {autoAlpha:0, duration:1}, "-=1")  // Fade out the second slide

  // Slide 3: Hero3 and Loadbar3
    .to(".loadbar3", {width:"100%", duration:7, ease:"none"})
    .to(".hero-text3", {opacity: 1}, "<")
    .to(".loadbar3", {width:"0", duration:0})
    .to(".hero-text3", {opacity: 0.7}, "<")
    .to(".hero1", {autoAlpha:1, duration:1}, "-=1")  // Fade in the first slide to loop back
    .to(".hero3", {autoAlpha:0, duration:1}, "-=1"); // Fade out the third slide
});

// Animations
gsap.fromTo(".show-from-bottom", {autoAlpha:0, y:200}, {scrollTrigger: "#levers", autoAlpha:1, y:0, delay:.75, stagger:.25});
gsap.fromTo(".show-from-right", {autoAlpha:0, x:200}, {scrollTrigger: "#posts", autoAlpha:1, x:0, delay:.75, stagger:.25});
